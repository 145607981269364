/* eslint-disable prettier/prettier */
<template>
  <div>
    <b-row class="mb-5">
      <b-col>
        <b-progress variant="success" :max="100" height="3rem">
          <b-progress-bar :value="progress">
            <span><strong>{{ progress }}%</strong></span>
          </b-progress-bar>
        </b-progress>
      </b-col>
    </b-row>
    <div>
      <div>
        <b-tabs pills card justified v-model="activeTab" class="shadow" style="border-radius:10px;">
          
          <b-tab active title="Profil" style="background-color:white;margin-top:0px;">
            <b-row>
              <input style="display: none;" @change="uploadFile" type="file" ref="fileInput"/>
              <b-col cols="12">
                <b-row class="pt-3 pb-3 d-flex align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Unggah Logo Organisasi" }}</p></b-col
                  >:<b-col sm="2">
                    <b-button v-if="file_logo == ''" variant="primary" size="sm" @click="fileClicked('logo')"><v-icon>mdi-upload</v-icon> Unggah</b-button>
                    <!-- <a :href="fileURL + file_logo" target="_blank" type="button">Lihat File</a> -->
                    <a v-if="file_logo != ''" :href="fileURL + file_logo" target="_blank" type="button">
                    <b-button variant="success" size="sm">
                      Lihat File
                    </b-button>
                    </a>
                    <b-button v-if="file_logo != ''" @click="file_logo = ''" variant="danger" size="sm" class="ml-3">
                      <v-icon class="p-0">mdi-delete-outline</v-icon>
                    </b-button>

                  </b-col>
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Unggah Bendera Organisasi" }}</p></b-col
                  >:<b-col sm="3">
                    <b-button v-if="file_bendera == ''" variant="primary" size="sm" @click="fileClicked('bendera')"><v-icon>mdi-upload</v-icon> Unggah</b-button>
                    <a v-if="file_bendera != ''" :href="fileURL + file_bendera" target="_blank" type="button">
                    <b-button variant="success" size="sm">
                      Lihat File
                    </b-button>
                    </a>
                    <b-button v-if="file_bendera != ''" @click="file_bendera = ''" variant="danger" size="sm" class="ml-3">
                      <v-icon class="p-0">mdi-delete-outline</v-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Nama Ormas" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-input :disabled="statDis && statDis.nama_ormas != null && statDis.nama_ormas != ''" v-model="$v.formProfil.nama_ormas.$model" :state="validateState('nama_ormas')" placeholder="Silahkan masukkan nama ormas"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Singkatan" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-input :disabled="statDis && statDis.nama_singkatan != null && statDis.nama_singkatan != ''" v-model="$v.formProfil.singkatan.$model" :state="validateState('singkatan')" placeholder="Silahkan masukkan singkatan"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 d-flex align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Nomor SKT Aktif" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-input :disabled="statDis && statDis.no_skt != null && statDis.no_skt != ''" placeholder="Silahkan masukkan nomor SKT aktif" v-model="$v.formProfil.no_skt.$model" :state="validateState('no_skt')"></b-form-input>
                  </b-col>
                  <b-col sm="2">
                    <b-button v-if="statDis.file_esign_skt != null" @click="newTab(file_skt)" size="sm" variant="success">
                      <v-icon style="color:white;" small>
                        mdi-eye
                      </v-icon>
                        Lihat File
                    </b-button>
                    <b-button v-else-if="file_skt == null || file_skt == ''" variant="primary" size="sm" @click="fileClicked('skt')">
                      <v-icon>
                        mdi-upload
                      </v-icon>
                        Unggah
                    </b-button>
                    <a v-else-if="file_skt != ''" type="button">
                      <b-button :href="fileURL + file_skt" target="_blank" variant="success" size="sm">
                        Lihat File
                      </b-button>
                      <b-button @click="file_skt = ''" variant="danger" size="sm" class="ml-3">
                        <v-icon class="p-0">
                          mdi-delete-outline
                        </v-icon>
                      </b-button>
                    </a>
                    
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">
                      {{ "Status Sekretariat" }}<span class="text-danger">*</span>
                    </p></b-col
                  >:<b-col sm="6">
                    
                    <b-form-select disabled v-model="$v.formProfil.status_sekretariat.$model" :state="validateState('status_sekretariat')">
                      <!-- <b-form-select-option value="" disabled>Silahkan masukkan status sekretariat</b-form-select-option> -->
                      <b-form-select-option :value="item.id" v-for="(item,index) in listSekretariat" :key="index">{{item.namaLokasi}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">
                      {{ "Alamat sekretariat" }}<span class="text-danger">*</span>
                    </p></b-col
                  >:<b-col sm="6">
                    <b-form-textarea
                      id="textarea"
                      :disabled="statDis && statDis.alamat_sekretariat != null && statDis.alamat_sekretariat != ''"
                      placeholder="Silahkan masukkan alamat sekretariat"
                      v-model="$v.formProfil.alamat_sekretariat.$model" :state="validateState('alamat_sekretariat')"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Propinsi" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-select :disabled="statDis && statDis.kode_prov_alamat != null && statDis.kode_prov_alamat != ''" v-model="$v.selectedPropinsi.$model" :state="validateStateN('selectedPropinsi')">
                      <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                      <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Kabupaten/Kota" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-select :disabled="statDis && statDis.kode_kabkota_alamat != null && statDis.kode_kabkota_alamat != '' && statDis.kode_kabkota_alamat != '-'" v-model="$v.formProfil.kabupaten_kota_sekretariat.$model" :state="validateState('kabupaten_kota_sekretariat')">
                      <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten/kota</b-form-select-option>
                        {{ listKota ? '' : ''}}
                        <b-form-select-option :value="item.kode" v-for="(item,index) in listKota" :key="index">{{item.nama}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Nomor Telepon" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-input :disabled="statDis && statDis.no_tlp != null && statDis.no_tlp != ''" v-model="$v.formProfil.no_telp.$model" :state="validateState('no_telp')" placeholder="Silahkan masukkan nomor telepon"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Nomor FAX" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-input :disabled="statDis && statDis.no_fax != null && statDis.no_fax != ''" v-model="$v.formProfil.no_fax.$model" :state="validateState('no_fax')" placeholder="Silahkan masukkan nomor fax"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Email" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-input :disabled="statDis && statDis.email_ormas != null && statDis.email_ormas != ''" v-model="$v.formProfil.email.$model" :state="validateState('email')" placeholder="Silahkan masukkan email"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">
                      {{ "Tujuan organisasi" }}<span class="text-danger">*</span>
                    </p></b-col
                  >:
                  <b-col sm="6">
                      <b-form-input :disabled="statDis && statDis.tujuan_ormas != null && statDis.tujuan_ormas != ''" v-model="$v.formProfil.tujuan_ormas.$model" :state="validateState('tujuan_ormas')" placeholder="Silahkan masukkan tujuan organisasi"></b-form-input>
                  </b-col>
                </b-row>
                
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "NPWP" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-input :disabled="statDis && statDis.no_npwp != null && statDis.no_npwp != ''" v-model="$v.formProfil.npwp.$model" :state="validateState('npwp')" placeholder="Silahkan masukkan NPWP"></b-form-input>
                  </b-col>
                  <b-col sm="2">
                    <b-button v-if="statDis.file_scan_npwp != null" @click="newTab(file_npwp)" size="sm" variant="success">
                      <v-icon style="color:white;" small>
                        mdi-eye
                      </v-icon>
                      Lihat File
                    </b-button>
                    <b-button v-else-if="file_npwp == null || file_npwp == ''" variant="primary" size="sm" @click="fileClicked('npwp')">
                      <v-icon>
                        mdi-upload
                      </v-icon>
                      Unggah
                    </b-button>
                    <a v-else-if="file_npwp != ''" type="button">
                      <b-button :href="fileURL + file_npwp" target="_blank" variant="success" size="sm">
                        Lihat File
                      </b-button>
                      <b-button @click="file_npwp = ''" variant="danger" size="sm" class="ml-3">
                        <v-icon class="p-0">
                          mdi-delete-outline
                        </v-icon>
                      </b-button>
                    </a>
                    
                    
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Ciri khusus" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="6">
                    <b-form-select v-model="$v.formProfil.m_ciri_khusus_id.$model" :state="validateState('m_ciri_khusus_id')">
                      <b-form-select-option value="" disabled>Silahkan masukkan Ciri Khusus</b-form-select-option>
                      <b-form-select-option :value="item.id" v-for="(item,index) in listCiriKhusus" :key="index">{{item.namaCiriKhusus}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row :class="formProfil.bidang_kegiatan.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Bidang Kegiatan" }}<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="8">
                    <b-row v-for="(i,index) in formProfil.bidang_kegiatan" :key="index" class="pb-2">
                      <b-col>
                        <!-- <div class="d-flex align-items-center"> -->
                          <b-row>
                            <b-col cols="9" class="pr-0">
                              <b-form-select v-model="$v.formProfil.bidang_kegiatan.$each.$iter[index].id.$model" :state="validateStateFPBid(index)" style="max-width: 478px;">
                                <b-form-select-option value="" disabled>Silahkan masukkan Bidang Kegiatan</b-form-select-option>
                                <b-form-select-option :value="item.id" v-for="(item,index) in listBidangKegiatan" :key="index">{{item.namaJenisOrmas}}</b-form-select-option>
                              </b-form-select>
                            </b-col>
                            <b-col cols="3" class="d-flex align-items-center pl-0">
                              <div class="button-minus" v-if="index != 0 && formProfil.bidang_kegiatan.length - index == 1"><v-icon style="color:white;" small @click="formProfil.bidang_kegiatan.splice(-1)">mdi-minus</v-icon></div>
                              <div class="button-plus" v-if="formProfil.bidang_kegiatan.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                              <div class="button-plus" v-if="formProfil.bidang_kegiatan.length - index == 1"><v-icon style="color:white;" small @click="formProfil.bidang_kegiatan.push({id: ''})">mdi-plus</v-icon></div>
                            </b-col>
                          </b-row>
                        <!-- </div> -->
                      </b-col>
                    
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Wadah Berhimpun" }}
                      <!-- <span class="text-danger">*</span> -->
                      </p></b-col
                  >:<b-col sm="8">
                          <b-row>
                            <b-col cols="9" class="pr-0">
                              <b-form-select style="max-width: 478px;" v-model="formProfil.wadahBerhimpun" >
                                <b-form-select-option value="" disabled>Silahkan masukkan Wadah Berhimpun</b-form-select-option>
                                <b-form-select-option :value="item.id" v-for="(item,index) in listBadanOtonom" :key="index">{{item.nama_ormas}}</b-form-select-option>
                              </b-form-select>
                            </b-col>
                          </b-row>
                  </b-col>
                </b-row>
                <b-row :class="formProfil.badan_otonom.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">{{ "Badan Otonom / Sayap Organisasi" }}
                      <!-- <span class="text-danger">*</span> -->
                      </p></b-col
                  >:<b-col sm="8">
                    <b-row v-for="(i,index) in formProfil.badan_otonom" :key="index" class="pb-2">
                      <b-col>
                          <b-row>
                            <b-col cols="9" class="pr-0">
                              <b-form-select style="max-width: 478px;" v-model="formProfil.badan_otonom[index].id" >
                                <b-form-select-option value="" disabled>Silahkan masukkan Badan Otonom / Sayap Organisasi</b-form-select-option>
                                <b-form-select-option :value="item.id" v-for="(item,index) in listBadanOtonom" :key="index">{{item.nama_ormas}}</b-form-select-option>
                              </b-form-select>
                            </b-col>
                            <b-col cols="3" class="d-flex align-items-center pl-0">
                              <div class="button-minus" v-if="index != 0 && formProfil.badan_otonom.length - index == 1"><v-icon style="color:white;" small @click="formProfil.badan_otonom.splice(-1)">mdi-minus</v-icon></div>
                              <div class="button-plus" v-if="formProfil.badan_otonom.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                              <div class="button-plus" v-if="formProfil.badan_otonom.length - index == 1"><v-icon style="color:white;" small @click="formProfil.badan_otonom.push({id:''})">mdi-plus</v-icon></div>
                            </b-col>
                          </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col class="d-flex justify-content-center pt-5 mt-5">
                <b-button variant="primary" size="lg" style="width:200px;" @click="cekProfile()">
                  Lanjut
                </b-button>
              </b-col>
            </b-row>

          </b-tab>

          <b-tab title="Info Pendirian" style="background-color:white;margin-top:0px;"
            ><b-row>
              <b-col cols="12">
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">Nama Notaris<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="4">
                    <b-form-input v-model="$v.formPendiri.namaNotaris.$model" :state="validateState2('namaNotaris')" placeholder="Silahkan masukkan nama notaris"></b-form-input>
                     </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="4">
                    <b-form-input :disabled="statDis && statDis.no_akta_notaris != null && statDis.no_akta_notaris != ''" v-model="$v.formPendiri.nomorAkta.$model" :state="validateState2('nomorAkta')" placeholder="Silahkan masukkan nomor akta pendirian"></b-form-input>
                  </b-col>
                  <b-col sm="2">
                    <b-button v-if="statDis.file_scan_notaris != null" @click="newTab(file_akta)" size="sm" variant="success">
                      <v-icon style="color:white;" small>
                        mdi-eye
                      </v-icon>
                        Lihat File
                    </b-button>
                    <b-button v-else-if="file_akta == null || file_akta == ''" variant="primary" size="sm" @click="fileClicked('scanNotaris')">
                      <v-icon>
                        mdi-upload
                      </v-icon>
                        Unggah
                    </b-button>
                    <a v-else-if="file_akta != ''" type="button">
                      <b-button :href="fileURL + file_akta" target="_blank" variant="success" size="sm">
                        Lihat File
                      </b-button>
                      <b-button @click="file_akta = ''" variant="danger" size="sm" class="ml-3">
                        <v-icon class="p-0">
                          mdi-delete-outline
                        </v-icon>
                      </b-button>
                    </a>
                  </b-col>
                </b-row>
               <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">Tanggal AKTA Pendirian<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="4">
                    
                    <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="$v.formPendiri.tanggalAkta.$model" :state="validateState2('tanggalAkta')" id="tanggalAkta" class="mb-2"></b-form-datepicker>
                  </b-col>
                </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">Tempat Pendirian<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="4">
                    <b-form-select v-model="$v.selectedPendiriPropinsi.$model" :state="validateStateP('selectedPendiriPropinsi')">
                      <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                      <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                  <b-col sm="4">
                    <b-form-select v-model="$v.formPendiri.kabupatenKota.$model" :state="validateState2('kabupatenKota')">
                      <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten</b-form-select-option>
                      {{ listKota2 ? '' : ''}}
                        <b-form-select-option :value="item.kode" v-for="(item,index) in listKota2" :key="index">{{item.nama}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                </b-row>
                 <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">Tanggal Pendirian<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="4">
                    <b-form-datepicker :disabled="statDis && statDis.tanggal_pendirian != null  && statDis.tanggal_pendirian != ''" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="$v.formPendiri.tanggalPendirian.$model" :state="validateState2('tanggalPendirian')" id="tanggalPendirian"  class="mb-2"></b-form-datepicker>
                  </b-col>
                </b-row>
                 <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">Nomor Surat Pendaftaran<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="4">
                    <b-form-input :disabled="statDis && statDis.no_surat_permohonan != null && statDis.no_surat_permohonan != ''" v-model="$v.formPendiri.nomorSuratPendaftaran.$model" :state="validateState2('nomorSuratPendaftaran')" placeholder="Silahkan masukkan nomor surat pendaftaran"></b-form-input>
                  </b-col>
                  <b-col sm="2">
                    <b-button v-if="statDis.file_scan_surat_permohonan != null" @click="newTab(file_surat_pendaftaran)" size="sm" variant="success">
                      <v-icon style="color:white;" small>
                        mdi-eye
                      </v-icon>
                        Lihat File
                    </b-button>
                    <b-button v-else-if="file_surat_pendaftaran == null || file_surat_pendaftaran == ''" variant="primary" size="sm" @click="fileClicked('suratPermohonan')">
                      <v-icon>
                        mdi-upload
                      </v-icon>
                        Unggah
                    </b-button>
                    <a v-else-if="file_surat_pendaftaran != ''" type="button">
                      <b-button :href="fileURL + file_surat_pendaftaran" target="_blank" variant="success" size="sm">
                        Lihat File
                      </b-button>
                      <b-button @click="file_surat_pendaftaran = ''" variant="danger" size="sm" class="ml-3">
                        <v-icon class="p-0">
                          mdi-delete-outline
                        </v-icon>
                      </b-button>
                    </a>
                  </b-col>

                </b-row>
                 <b-row class="pt-3 pb-3 align-items-center">
                  <b-col sm="3"
                    ><p style="font-weight: 600; margin:0px;">Tanggal Surat Pendaftaran<span class="text-danger">*</span></p></b-col
                  >:<b-col sm="4">
                    <b-form-datepicker :disabled="statDis && statDis.tgl_surat_permohonan != null && statDis.tgl_surat_permohonan != ''" 
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="tanggalSuratPendaftaran" 
                    v-model="$v.formPendiri.tanggalSuratPendaftaran.$model" :state="validateState2('tanggalSuratPendaftaran')" class="mb-2"></b-form-datepicker>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col class="d-flex justify-content-center pt-5 mt-5">
                <button
                  class="mt-16 text-center button-back mr-5"
                  @click="back()"
                >
                  Kembali
                </button>
                <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="cekInfoPendirian()">
                  Lanjut
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Struktur Organisasi" style="background-color:white;margin-top:0px;">
            <b-card-text class="pl-0 pr-0">
              <div class="card-body pt-0 pb-3 pl-0 pr-0">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table
                      class="
                        table
                        table-head-custom
                        table-vertical-center
                        table-head-bg
                        table-bordered
                      "
                    >
                      <thead>
                        <tr class="text-left">
                          <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                          <th style="padding-left:0px; padding-right: 0px;width: 230px;" class="text-capitalize">Nama</th>
                          <th style="padding-left:0px; padding-right: 0px;width: 350px;" class="text-capitalize">Masa Bakti</th>
                          <th style="padding-left:0px; padding-right: 0px;width: 150px;" class="text-capitalize">Jabatan</th>
                          <th style="padding-left:0px; padding-right: 0px;width: 230px;" class="text-capitalize">No. Identitas</th>
                          <!-- <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tempat</th> -->
                          <th style="padding-left:0px; padding-right: 0px;width: 180px;" class="text-capitalize">Tanggal Lahir</th>
                          <th style="padding-left:0px; padding-right: 0px;width: 180px;" class="text-capitalize">No. HP</th>
                          <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">KTP</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in $v.formStruktur.pengurusInti.$each.$iter">
                          <tr v-bind:key="i">
                            <td class="d-flex justify-content-center">
                              <div  v-if="formStruktur.pengurusInti[i].foto == ''" @click="fileClicked('peng_foto' + i)" style="background: #C4C4C4;width: 56px;height: 56px;border-radius: 10px;display:flex; align-items:center;justify-content:center;cursor: pointer;">
                                <v-icon>mdi-camera</v-icon>
                              </div>
                              <img v-if="formStruktur.pengurusInti[i].foto !== ''"
                                @click="fileClicked('peng_foto' + i)"
                                :src="fileURL + formStruktur.pengurusInti[i].foto"
                                style="width: 56px;height: 56px;"
                                alt="abstract"
                                @error="replaceByDefault"
                              />
                            </td>
                            <td>
                              <b-form-input :disabled="(formStruktur.pengurusInti[i].nameDis == true) && formStruktur.pengurusInti[i].nameDis != true" v-model="item.nama_pengurus.$model" :state="validateStateSOid(i, 'nama_pengurus')" :placeholder="'Nama'"></b-form-input>
                            </td>
                            <td>
                              <div class="d-flex justify-content-between align-items-center">
                                <!-- <b-form-input
                                  
                                  :disabled="formStruktur.pengurusInti[i].pmDis"
                                  :id="'mulai'+ i"
                                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                  v-model="item.periode_mulai.$model"
                                  :state="validateStateSOid(i, 'periode_mulai')"
                                  placeholder="Mulai" 
                                  style="height:40px;margin-right:5px;"
                                ></b-form-input> - 
                                <b-form-input
                                  
                                  :id="'akhir'+ i"
                                  :disabled="formStruktur.pengurusInti[i].psDis"
                                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                  v-model="item.periode_selesai.$model"
                                  :state="validateStateSOid(i, 'periode_selesai')"
                                  placeholder="Selesai" 
                                  style="height:40px;margin-left:5px;"
                                ></b-form-input> -->
                              <b-form-datepicker
                              :disabled="formStruktur.pengurusInti[i].pmDis"
                              :id="'mulai'+ i"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              v-model="item.periode_mulai.$model"
                              :state="validateStateSOid(i, 'periode_mulai')"
                              placeholder="Mulai" 
                              style="height:40px;margin-right:5px;"
                              ></b-form-datepicker> - 
                              <b-form-datepicker
                              :id="'akhir'+ i"
                              :disabled="formStruktur.pengurusInti[i].psDis"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                              v-model="item.periode_selesai.$model"
                              :state="validateStateSOid(i, 'periode_selesai')"
                              placeholder="Selesai" 
                              style="height:40px;margin-left:5px;"
                              ></b-form-datepicker>
                              </div>
                            </td>
                            <td>
                              <b-form-select v-model="item.m_jabatan_id.$model" :state="validateStateSOid(i, 'm_jabatan_id')">
                                <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                                <b-form-select-option :value="item.id" v-for="(item,index) in listJabatan" :key="index">{{item.namaJabatan}}</b-form-select-option>
                              </b-form-select>
                            </td>
                            <td>
                              <b-form-input :disabled="formStruktur.pengurusInti[i].idDis" v-model="item.identitas.$model" :state="validateStateSOid(i, 'identitas')" placeholder="No Identitas"></b-form-input>
                            </td>
                            <td>
                              <b-form-datepicker :id="'er'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                              v-model="item.tanggal_lahir.$model" :state="validateStateSOid(i, 'tanggal_lahir')" placeholder="TTL" 
                              style="height:40px;" class="mb-2"></b-form-datepicker>
                            </td>
                            <td>
                              <b-form-input placeholder="No HP" v-model="item.no_hp.$model" :state="validateStateSOid(i, 'no_hp')"></b-form-input>
                            </td>
                            <td>
                              <b-button v-if="formStruktur.pengurusInti[i].file_identitas" @click="newTab(formStruktur.pengurusInti[i].file_identitas)" size="sm" variant="success">
                                <v-icon style="color:white;" small>
                                  mdi-eye
                                </v-icon>
                              </b-button>
                              <b-button v-else-if="formStruktur.pengurusInti[i].file_identitas == '' || formStruktur.pengurusInti[i].file_identitas == null" variant="primary" size="sm" @click="fileClicked('peng_ktp' + i)">
                                <v-icon>
                                  mdi-upload
                                </v-icon>
                              </b-button>
                              <a v-else-if="formStruktur.pengurusInti[i].file_identitas != ''" type="button">
                                <b-button :href="fileURL + formStruktur.pengurusInti[i].file_identitas" target="_blank" variant="success" size="sm"></b-button>
                                <b-button @click="formStruktur.pengurusInti[i].file_identitas = ''" variant="danger" size="sm" class="ml-3">
                                  <v-icon class="p-0">
                                    mdi-delete-outline
                                  </v-icon>
                                </b-button>
                              </a>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                    
                  <!--end::Table-->
                  <div class="mt-5">

                    <b-row :class="formStruktur.pengurus.pendiri.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                      <b-col sm="3"
                      ><p style="font-weight: 600; margin:0px;">Daftar Pendiri Organisasi<span class="text-danger">*</span></p></b-col
                      >:<b-col sm="8">
                        <b-row v-for="(i,index) in formStruktur.pengurus.pendiri" :key="index" class="pb-2">
                          <b-col cols="10" class="pr-0 d-flex">
                            <b-form-input :disabled="formStruktur.pengurus.pendiri[index].dis" v-model="$v.formStruktur.pengurus.pendiri.$each.$iter[index].nama_pengurus.$model" :state="validateStateSOP(index, 'nama_pengurus')" placeholder="Silahkan masukkan daftar pendiri organisasi"></b-form-input>
                            <b-form-input :disabled="formStruktur.pengurus.pendiri[index].disid" v-model="$v.formStruktur.pengurus.pendiri.$each.$iter[index].identitas.$model" :state="validateStateSOP(index, 'identitas')" class="ml-2" placeholder="Silahkan masukkan NIK daftar pendiri organisasi"></b-form-input>
                          </b-col>
                          <b-col cols="2" class="d-flex align-items-center pl-0">
                            <div class="button-minus" v-if="index != 0 && formStruktur.pengurus.pendiri.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pendiri.splice(-1)">mdi-minus</v-icon></div>
                            <div class="button-plus" v-if="formStruktur.pengurus.pendiri.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                            <div class="button-plus" v-if="formStruktur.pengurus.pendiri.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pendiri.push({nama_pengurus:'',identitas:''})">mdi-plus</v-icon></div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row :class="formStruktur.pengurus.pembina.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                      <b-col sm="3"
                      ><p style="font-weight: 600; margin:0px;">Daftar Pembina Organisasi<span class="text-danger">*</span></p></b-col
                      >:<b-col sm="8">
                        <b-row v-for="(i,index) in formStruktur.pengurus.pembina" :key="index" class="pb-2">
                          <b-col cols="10" class="pr-0 d-flex">
                            <b-form-input :disabled="formStruktur.pengurus.pembina[index].dis" v-model="$v.formStruktur.pengurus.pembina.$each.$iter[index].nama_pengurus.$model" :state="validateStateSOPM(index, 'nama_pengurus')" placeholder="Silahkan masukkan daftar pembina organisasi"></b-form-input>
                            <b-form-input :disabled="formStruktur.pengurus.pembina[index].disid" v-model="$v.formStruktur.pengurus.pembina.$each.$iter[index].identitas.$model" :state="validateStateSOPM(index, 'identitas')" class="ml-2" placeholder="Silahkan masukkan NIK daftar pembina organisasi"></b-form-input>
                          </b-col>
                          <b-col cols="2" class="d-flex align-items-center pl-0">
                            <div class="button-minus" v-if="index != 0 && formStruktur.pengurus.pembina.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pembina.splice(-1)">mdi-minus</v-icon></div>
                            <div class="button-plus" v-if="formStruktur.pengurus.pembina.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                            <div class="button-plus" v-if="formStruktur.pengurus.pembina.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pembina.push({nama_pengurus:'',identitas:''})">mdi-plus</v-icon></div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row :class="formStruktur.pengurus.penasehat.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                      <b-col sm="3"
                      ><p style="font-weight: 600; margin:0px;">Daftar Penasehat Organisasi<span class="text-danger">*</span></p></b-col
                      >:<b-col sm="8">
                        <b-row v-for="(i,index) in formStruktur.pengurus.penasehat" :key="index" class="pb-2">
                          <b-col cols="10" class="pr-0 d-flex">
                            <b-form-input :disabled="formStruktur.pengurus.penasehat[index].dis" v-model="$v.formStruktur.pengurus.penasehat.$each.$iter[index].nama_pengurus.$model" :state="validateStateSOPN(index, 'nama_pengurus')" placeholder="Silahkan masukkan daftar penasehat organisasi"></b-form-input>
                            <b-form-input :disabled="formStruktur.pengurus.penasehat[index].disid" v-model="$v.formStruktur.pengurus.penasehat.$each.$iter[index].identitas.$model" :state="validateStateSOPN(index, 'identitas')" class="ml-2" placeholder="Silahkan masukkan NIK daftar penasehat organisasi"></b-form-input>
                          </b-col>
                          <b-col cols="2" class="d-flex align-items-center pl-0">
                            <div class="button-minus" v-if="index != 0 && formStruktur.pengurus.penasehat.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.penasehat.splice(-1)">mdi-minus</v-icon></div>
                            <div class="button-plus" v-if="formStruktur.pengurus.penasehat.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                            <div class="button-plus" v-if="formStruktur.pengurus.penasehat.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.penasehat.push({nama_pengurus:'',identitas:''})">mdi-plus</v-icon></div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row :class="formStruktur.pengurus.pelindung.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                      <b-col sm="3"
                      ><p style="font-weight: 600; margin:0px;">Daftar Pelindung Organisasi</p></b-col
                      >:<b-col sm="8">
                        <b-row v-for="(i,index) in formStruktur.pengurus.pelindung" :key="index" class="pb-2">
                          <b-col cols="10" class="pr-0 d-flex">
                            <b-form-input v-model="formStruktur.pengurus.pelindung[index].nama_pengurus" placeholder="Silahkan masukkan daftar pelindung organisasi"></b-form-input>
                            <b-form-input v-model="formStruktur.pengurus.pelindung[index].identitas" class="ml-2" placeholder="Silahkan masukkan NIK daftar pelindung organisasi"></b-form-input>
                          </b-col>
                          <b-col cols="2" class="d-flex align-items-center pl-0">
                            <div class="button-minus" v-if="index != 0 && formStruktur.pengurus.pelindung.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pelindung.splice(-1)">mdi-minus</v-icon></div>
                            <div class="button-plus" v-if="formStruktur.pengurus.pelindung.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                            <div class="button-plus" v-if="formStruktur.pengurus.pelindung.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pelindung.push({nama_pengurus:'',identitas:''})">mdi-plus</v-icon></div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>

                    <b-row class="pt-3 d-flex align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Unggah SK Pengurus<span class="text-danger">*</span></p></b-col
                      >:<b-col sm="6">
                          <b-button v-if="file_sk_pengurus != null && file_sk_pengurus != ''" @click="newTab(file_sk_pengurus)" size="sm" variant="success">
                            <v-icon style="color:white;" small>
                              mdi-eye
                            </v-icon>
                            Lihat File
                          </b-button>
                          <b-button v-else-if="file_sk_pengurus == null || file_sk_pengurus == ''" variant="primary" size="sm" @click="fileClicked('skPengurus')">
                            <v-icon>
                              mdi-upload
                            </v-icon>
                            Unggah
                          </b-button>
                          <a v-else-if="file_sk_pengurus != ''" type="button">
                            <b-button :href="fileURL + file_sk_pengurus" target="_blank" variant="success" size="sm">
                              Lihat File
                            </b-button>
                            <b-button @click="file_sk_pengurus = ''" variant="danger" size="sm" class="ml-3">
                              <v-icon class="p-0">
                                mdi-delete-outline
                              </v-icon>
                            </b-button>
                          </a>
                        </b-col>
                    </b-row>
                     <b-row class="mt-5">
                      <b-col class="d-flex justify-content-center pt-5 mt-5">
                        <button
                          class="mt-16 text-center button-back mr-5"
                          @click="back()"
                        >
                          Kembali
                        </button>
                        <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="cekStruktur()">
                          Lanjut
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-tab>

          <b-tab title="Rekening" style="background-color:white;margin-top:0px;">
            <div class="table-responsive">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis Rekening</th>
                    <th style="width: 100px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, index) in formRekening">
                    <tr v-bind:key="index">
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ index + 1 }}</span
                        >
                      </td>
                      <!-- <td>
                        <b-form-input placeholder="Status Sekretariat"></b-form-input>
                      </td>
                      <td>
                        <b-form-input placeholder="Kabupaten/Kota"></b-form-input>
                      </td> -->
                      <td>
                        <b-form-input v-model="$v.formRekening.$each.$iter[index].no_rekening.$model" :state="validateStateRek(index, 'no_rekening')" placeholder="Nomor Rekening"></b-form-input>
                      </td>
                      <td>
                        <b-form-input v-model="$v.formRekening.$each.$iter[index].nama_bank.$model" :state="validateStateRek(index, 'nama_bank')" placeholder="Nama Bank"></b-form-input>
                      </td>
                       <td>
                        <b-form-input v-model="$v.formRekening.$each.$iter[index].nama_pemilik_rekening.$model" :state="validateStateRek(index, 'nama_pemilik_rekening')" placeholder="Pemilik Rekening"></b-form-input>
                      </td>
                       <td>
                        <b-form-select v-model="$v.formRekening.$each.$iter[index].id_jenis_rekening.$model" :state="validateStateRek(index, 'id_jenis_rekening')">
                          <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                          <b-form-select-option :value="item.id" v-for="(item,index) in listRekening" :key="index">{{item.nama}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="d-flex justify-content-center">
                        <div class="button-plus" v-if="formRekening.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                        <div class="button-minus" v-if="index != 0 && formRekening.length - index == 1"><v-icon style="color:white;" small @click="formRekening.splice(-1)">mdi-minus</v-icon></div>
                        <div class="button-plus" v-if="formRekening.length - index == 1"><v-icon style="color:white;" small @click="addRek(index)">mdi-plus</v-icon></div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <b-row class="mt-5">
              <b-col class="d-flex justify-content-center pt-5 mt-5">
                <button
                  class="mt-16 text-center button-back mr-5"
                  @click="back()"
                >
                  Kembali
                </button>
                <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="cekRekening()">
                  Lanjut
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Cabang Organisasi" style="background-color:white;margin-top:0px;">
            <b-row class="mb-5">
              <b-col class="d-flex justify-content-between">
                <span style="font-size:24px;font-weight:bold;">Silahkan Tambah Cabang (Opsional)</span>
                <b-button @click="addCabang()" variant="primary" size="sm"><v-icon dark>mdi-plus-box</v-icon> Tambah Cabang</b-button>
              </b-col>
            </b-row>

            <div class="table-responsive mt-5">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                    <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                    <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                    <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                    <th style="width: 100px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in listCabang">
                    <tr v-bind:key="i">
                      <td>
                        <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      ">{{ i + 1}}</span>
                      </td>
                      <td>
                        <span class="
                        text-dark-75
                        text-left
                        font-weight-bolder
                        d-block
                        font-size-lg
                      ">{{ item.masterLokasiSekretariat.nama_lokasi}}</span>
                      </td>
                      <td>
                        <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      " v-if="item.alamat_sekretariat">{{ item.alamat_sekretariat.length > 30 ? item.alamat_sekretariat.substring(0,30) + "..." : item.alamat_sekretariat  }}</span>
                      </td>
                      <td>
                        <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      " v-if="item.wilayahKabupaten">{{ item.wilayahKabupaten.nama }}</span>
                      </td>
                      <td>
                        <span class="
                        text-dark-75
                        text-left
                        font-weight-bolder
                        d-block
                        font-size-lg
                      " v-if="item.wilayahProvinsi">{{ item.wilayahProvinsi.nama }}</span>
                      </td>
                      <td>
                        <b-button variant="danger" size="sm" style="padding:5px" class="mr-1" @click="deleteCabang(item.id)">
                          <v-icon style="color:white;padding:0px;" small>mdi-trash-can-outline</v-icon>
                        </b-button>
                        <b-button variant="primary" size="sm" style="padding:5px" class="mr-1">
                          <v-icon style="color:white;padding:0px;" @click="editCabang(item.id)" small>mdi-pencil</v-icon>
                        </b-button>
                        <!-- <b-button
                          variant="success"
                          size="sm"
                          @click="getDetailCabang(item.id)"
                        >
                          >> Detail
                        </b-button> -->
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>            
            <b-row class="mt-5">
              <b-col class="d-flex justify-content-center pt-5 mt-5">
                <button
                  class="mt-16 text-center button-back mr-5"
                  @click="back()"
                >
                  Kembali
                </button>
                <b-button :disabled="loadingSubmit" class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="submitKelengkapan()">
                  
                  <span v-if="!loadingSubmit">Selesai</span>
                  <b-spinner medium v-else ></b-spinner>
                </b-button>
              </b-col>
            </b-row>

          </b-tab>
        </b-tabs>
      </div>
    </div>
    <b-modal
      id="modal-form-cabang"
      ok-only
      centered
      size="xl"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container>
          <b-row>
            <b-col cols="12">
              <b-row class="pt-5 mt-2">
                <b-col class="d-flex justify-content-between">
                  <b style="font-size:16px;">
                    Profil - Cabang Organisasi
                  </b>
                  <div>
                    <b-button @click="saveCabang()" variant="success" size="sm" class="mr-3">
                      <v-icon class="pr-3" style="color:white;">mdi-content-save</v-icon>
                      <span v-if="createMode">Simpan</span>
                      <span v-else>Ubah</span>
                    </b-button>
                    <b-button variant="outline-secondary" size="sm" @click="$bvModal.hide('modal-form-cabang')">
                      <v-icon class="pr-3">mdi-close</v-icon>
                      Tutup
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">{{ "Nama Ormas" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input disabled v-model="$v.formCabang.nama_cabang.$model" :state="validateStateC('nama_cabang')" placeholder="Silahkan masukkan nama ormas"></b-form-input>
                </b-col>
              </b-row>
              <!-- <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">{{ "Singkatan" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input v-model="$v.formCabang.singkatan.$model" :state="validateStateC('singkatan')" placeholder="Silahkan masukkan singkatan"></b-form-input>
                </b-col>
              </b-row> -->
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">
                    {{ "Status Sekretariat" }}<span class="text-danger">*</span>
                  </p></b-col
                >:<b-col sm="6">
                   <b-form-select v-model="$v.formCabang.m_lokasi_sekretariat_id.$model" :state="validateStateC('m_lokasi_sekretariat_id')">
                      <b-form-select-option value="" disabled>Silahkan masukkan status sekretariat</b-form-select-option>
                      <b-form-select-option :value="item.id" v-for="(item,index) in listSekretariat" :key="index">{{item.namaLokasi}}</b-form-select-option>
                    </b-form-select>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">
                    {{ "Alamat sekretariat" }}<span class="text-danger">*</span>
                  </p></b-col
                >:<b-col sm="6">
                  <b-form-textarea
                  v-model="$v.formCabang.alamat_sekretariat.$model" :state="validateStateC('alamat_sekretariat')"
                    id="textarea"
                    placeholder="Silahkan masukkan alamat sekretariat"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-col>
              </b-row> 
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">{{ "Propinsi" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-select v-model="$v.selectedCabangPropinsi.$model" :state="validateStateCP('selectedCabangPropinsi')">
                    <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                    <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">{{ "Kabupaten/Kota" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-select v-model="$v.formCabang.kabupaten_kota_sekretariat.$model" :state="validateStateC('kabupaten_kota_sekretariat')">
                  <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten</b-form-select-option>
                    {{ listKota3 ? '' : ''}}
                      <b-form-select-option :value="item.kode" v-for="(item,index) in listKota3" :key="index">{{item.nama}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">{{ "Nomor Telepon" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input v-model="$v.formCabang.no_telp.$model" :state="validateStateC('no_telp')" placeholder="Silahkan masukkan nomor telepon"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600">{{ "Nomor FAX" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input v-model="$v.formCabang.no_fax.$model" :state="validateStateC('no_fax')" placeholder="Silahkan masukkan nomor fax"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">{{ "NPWP" }}<span class="text-danger">*</span></p></b-col
                >:<b-col sm="6">
                  <b-form-input v-model="$v.formCabang.npwp.$model" :state="validateStateC('npwp')" placeholder="Silahkan masukkan NPWP"></b-form-input>
                </b-col>
              </b-row>
              

              <b-row class="pt-5 mt-2">
                <b-col>
                  <b style="font-size:16px;">
                    Info Pendirian Cabang - Cabang Organisasi
                  </b>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Nama Notaris<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-input v-model="$v.formCabang.namaNotaris.$model" :state="validateStateC('namaNotaris')" placeholder="Silahkan masukkan nama notaris"></b-form-input>
                    </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-input v-model="$v.formCabang.nomorAkta.$model" :state="validateStateC('nomorAkta')" placeholder="Silahkan masukkan nomor akta pendirian"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Tanggal AKTA Pendirian<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-datepicker v-model="$v.formCabang.tanggalAkta.$model" :state="validateStateC('tanggalAkta')" id="pendirian" class="mb-2"></b-form-datepicker>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Nomor SK Susunan Pengurus<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-input v-model="$v.formCabang.nomorSuratPendaftaran.$model" :state="validateStateC('nomorSuratPendaftaran')" placeholder="Silahkan nomor SK susunan pengurus"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Tempat Pendirian<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-select v-model="$v.selectedCabangPropinsi2.$model" :state="validateStateCP2('selectedCabangPropinsi2')">
                    <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                    <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                  </b-form-select>
                </b-col>
                <b-col sm="4">
                  <b-form-select v-model="$v.formCabang.kabupatenKota.$model" :state="validateStateC('kabupatenKota')">
                    <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten</b-form-select-option>
                    {{ listKota4 ? '' : ''}}
                      <b-form-select-option :value="item.kode" v-for="(item,index) in listKota4" :key="index">{{item.nama}}</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
                <b-row class="pt-3 pb-3 align-items-center">
                <b-col sm="3"
                  ><p style="font-weight: 600; margin:0px;">Tanggal Pendirian<span class="text-danger">*</span></p></b-col
                >:<b-col sm="4">
                  <b-form-datepicker v-model="$v.formCabang.tanggalPendirian.$model" :state="validateStateC('tanggalPendirian')" id="pendiriancabang" class="mb-2"></b-form-datepicker>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="pt-5 mt-2 pb-5">
            <b-col>
              <b style="font-size:16px;">
                Struktur Organisasi - Cabang Organisasi<span class="text-danger">*</span>
              </b>
            </b-col>
          </b-row>

          <div class="table-responsive">
            <table
              class="
        table
        table-head-custom
        table-vertical-center
        table-head-bg
        table-bordered
      "
            >
              <thead>
                  <tr class="text-left">
                    <th style="width: 50px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                    <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                    <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                    <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                    <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                    <th style="width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                    <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in $v.formCabang.strukturOrganisasi.$each.$iter">
                    <tr v-bind:key="i">
                      {{item.foto}}
                      <td class="d-flex justify-content-center">
                       <div  v-if="item.foto == ''" @click="fileClicked('peng_foto_cab' + i)" style="background: #C4C4C4;width: 56px;height: 56px;border-radius: 10px;display:flex; align-items:center;justify-content:center;">
                                  <v-icon>mdi-camera</v-icon>
                                </div>
                          <img v-if="item.foto !== ''"
                            @click="fileClicked('peng_foto_cab' + i)"
                            :src="fileURL + formCabang.strukturOrganisasi[i].foto"
                            style="width: 56px;height: 56px;"
                            alt="abstract"
                            @error="replaceByDefault"
                          />
                      </td>
                      <td>
                          <b-form-input v-model="item.nama_pengurus.$model" :state="validateStateCS(i, 'nama_pengurus')" :placeholder="'Nama '+item.namaJabatan"></b-form-input>
                      </td>
                      <td>
                        <div class="d-flex justify-content-between align-items-center">
                        <b-form-datepicker :id="'mulaic'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        v-model="item.periode_mulai.$model" :state="validateStateCS(i, 'periode_mulai')" placeholder="Mulai" 
                        style="height:40px;width:130px;"></b-form-datepicker> - 
                        <b-form-datepicker :id="'akhirc'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        v-model="item.periode_selesai.$model" :state="validateStateCS(i, 'periode_selesai')" placeholder="Selesai" 
                        style="height:40px;width:130px;"></b-form-datepicker>
                        </div>
                      </td>
                      <td>
                        <b-form-select v-model="item.m_jabatan_id.$model" :state="validateStateCS(i, 'm_jabatan_id')">
                          <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                          <b-form-select-option :value="itm.id" v-for="(itm, ii) in listJabatan" :key="ii">{{itm.namaJabatan}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td>
                        <b-form-input v-model="item.identitas.$model" :state="validateStateCS(i, 'identitas')" placeholder="No Identitas"></b-form-input>
                      </td>
                      <td>
                        <b-form-datepicker :id="'erc'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        v-model="item.tanggal_lahir.$model" :state="validateStateCS(i, 'tanggal_lahir')" placeholder="TTL" 
                        style="height:40px;width:130px;" class="mb-2"></b-form-datepicker>
                      </td>
                      <td>
                        <b-form-input placeholder="No HP" v-model="item.no_hp.$model" :state="validateStateCS(i, 'no_hp')"></b-form-input>
                      </td>
                    </tr>
                  </template>
                </tbody>
            </table>
          </div>
          <b-row class="d-flex align-items-center">
            <b-col sm="3"
              ><p style="font-weight: 600; margin:0px;">Unggah SK Pengurus Cabang<span class="text-danger">*</span></p></b-col
            >:<b-col sm="6">
                <b-button v-if="file_sk_pengurus_cabang == ''" variant="primary" size="sm" @click="fileClicked('skPengurusCab')">
                  <v-icon>
                    mdi-upload
                  </v-icon>
                  Unggah
                </b-button>
                <a v-else-if="file_sk_pengurus_cabang != ''" :href="fileURL + file_sk_pengurus_cabang" target="_blank" type="button">
                  <b-button variant="success" size="sm">
                    Lihat File
                  </b-button>
                </a>
                <b-button v-if="file_sk_pengurus_cabang != ''" @click="file_sk_pengurus_cabang = ''" variant="danger" size="sm" class="ml-3">
                  <v-icon class="p-0">
                    mdi-delete-outline
                  </v-icon>
                </b-button>            
            </b-col>
          </b-row>

            <b-row class="pt-5 mt-2 pb-5">
            <b-col>
              <b style="font-size:16px;">
                Rekening - Cabang Organisasi<span class="text-danger">*</span>
              </b>
            </b-col>
          </b-row>

          <div class="table-responsive">
            <table class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-bordered
            ">
              <thead>
                  <tr class="text-left">
                    <th style="width: 20px">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis Rekening</th>
                    <th style="width: 100px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, index) in $v.formRekeningC.$each.$iter">
                    <tr v-bind:key="index">
                      <td>
                        <span
                          class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    font-size-lg
                  "
                          >{{ index + 1 }}</span
                        >
                      </td>
                      <td>
                        <b-form-input v-model="item.no_rekening.$model" :state="validateStateRekC(index, 'no_rekening')" placeholder="Nomor Rekening"></b-form-input>
                      </td>
                      <td>
                        <b-form-input v-model="item.nama_bank.$model" :state="validateStateRekC(index, 'nama_bank')" placeholder="Nama Bank"></b-form-input>
                      </td>
                       <td>
                        <b-form-input v-model="item.nama_pemilik_rekening.$model" :state="validateStateRekC(index, 'nama_pemilik_rekening')" placeholder="Pemilik Rekening"></b-form-input>
                      </td>
                       <td>
                        <b-form-select v-model="item.id_jenis_rekening.$model" :state="validateStateRekC(index, 'id_jenis_rekening')">
                          <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                          <b-form-select-option :value="dd.id" v-for="(dd,o) in listRekening" :key="o">{{dd.nama}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="d-flex justify-content-center">
                        <div class="button-plus" v-if="formRekeningC.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                        <div class="button-minus" v-if="index != 0 && formRekeningC.length - index == 1"><v-icon style="color:white;" small @click="formRekeningC.splice(-1)">mdi-minus</v-icon></div>
                        <div class="button-plus" v-if="formRekeningC.length - index == 1"><v-icon style="color:white;" small @click="addRekC(index)">mdi-plus</v-icon></div>
                      </td>
                    </tr>
                  </template>
                </tbody>
            </table>
          </div>
        </b-container>
        
      </div>
    </b-modal>

  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "Informasi",
  data() {
    return {
      statDis: {},
      statDisTemp: {},
      loadingSubmit: false,
      jenis_file: '',
      file_logo: '',
      file_bendera: '',
      file_skt: '',
      file_npwp: '',
      file_akta: '',
      file_surat_pendaftaran: '',
      file_sk_pengurus: '',
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL + '/',
      bidang_kegiatans: [''],
      daftar_pendiri: [''],
      progress:0,
      daftar_pembinan: [''],
      daftar_penasehat: [''],
      daftar_pelindung: [''],
      rekening: [''],
      rekeningcabang: [''],
      yearMB: [],
      yearMBend:[[],[],[]],
      activeTab: 0,
      formProfil: {
          file_esign_skt: '',
          file_scan_npwp: '',
          file_scan_notaris: '',
          file_scan_surat_permohonan: '',
          nama_ormas: '',
          singkatan: '',
          no_skt: '',
          status_sekretariat: 1,
          alamat_sekretariat: '',
          kabupaten_kota_sekretariat: '',
          provinsi_sekretariat: '',
          no_telp: '',
          no_fax: '',
          email: '',
          tujuan_ormas: '',
          npwp: '',
          m_ciri_khusus_id: '',
          bidang_kegiatan: [{id: ''}],
          wadahBerhimpun: '',
          badan_otonom: [{id: ''}]
      },
      formPendiri: {
        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: "",
        tanggalPendirian: "",
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: "",
        provinsi: '',
        kabupatenKota: '',
        file_akta: ''
      },
      formStruktur: {
        pengurusInti:[
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
            // file_identitas_url: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
            // file_identitas_url: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
            // file_identitas_url: ''
          }
        ],
        pengurus: {
          pendiri: [
            {
              nama_pengurus: "",
              identitas: ""
            }
          ],
          pembina: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ],
          penasehat: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ],
          pelindung: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ]
        }
      },
      formRekening: [
        {
          no_rekening: '',
          nama_bank: '',
          cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening: null
        }
      ],
      formCabang: {
        nama_cabang: '',
        no_telp: '',
        singkatan: '',
        no_fax: '',
        alamat_sekretariat: '',
        npwp: '',
        tanggal_berdiri: '',
        kabupaten_kota_sekretariat: '',
        m_lokasi_sekretariat_id: '',

        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: '',
        kabupatenKota: '',
        tanggalPendirian: '',
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: '-',
        strukturOrganisasi: [
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '', 
            foto: '',
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '', 
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '', 
            foto: ''
          }
        ]
      },
      formRekeningC: [
        {
          no_rekening: '',
          nama_bank: '',
          // cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening:''  
        }
      ],
      vProfile: false,
      vPendiri: false,
      vStruktur: false,
      vRekening: false,
      vCabang: false,
      listSekretariat:[],
      listPropinsi: [],
      listKota: [],
      listKota2: [],
      listKota3: [],
      listKota4: [],
      listCiriKhusus: [],
      listBidangKegiatan: [],
      listBadanOtonom: [],
      listJabatan: [],
      listRekening: [],
      selectedPropinsi: '',
      selectedPendiriPropinsi: '',
      selectedCabangPropinsi: '',
      selectedCabangPropinsi2: '',
      listCabang: [],
      createMode: true,
      idCabang: '',
      file_sk_pengurus_cabang: '',
    }
  },
  validations: {
    selectedPropinsi: {required},
    selectedPendiriPropinsi: {required},
    // file_skt: {required},
    formProfil: {
      nama_ormas: {
        required
      },
          singkatan: {
        required
      },
          no_skt: {
        required
      },
          status_sekretariat: {
        required
      },
          alamat_sekretariat: {
        required
      },
          kabupaten_kota_sekretariat: {
        required
      },
          no_telp: {
        required
      },
          no_fax: {
        required
      },
          email: {
        required,
        email
      },
          tujuan_ormas: {
        required
      },
          npwp: {
        required
      },
          m_ciri_khusus_id: {
        required
      },
      // wadahBerhimpun: {
      //   required
      // },
      bidang_kegiatan: {
        $each: {
          id:{required}
        }
      },
      // badan_otonom: {
      //   $each: {
      //     id: {required}
      //   }
      // }   
          // badan_otonom: [''],
    },
    formPendiri: {
        namaNotaris: {
          required
        },
        nomorAkta: {
          required
        },
        tanggalAkta: {
          required
        },
        // "2021-09-30T03:59:56.529Z",
        tanggalPendirian: {
          required
        },
        nomorSuratPendaftaran: {
          required
        },
        tanggalSuratPendaftaran: {
          required
        },
        kabupatenKota: {
          required
        },
    },
    formStruktur: {
      pengurusInti:{
        $each: {
          nama_pengurus: {required},
          periode_mulai: {required},
          periode_selesai: {required},
          m_jabatan_id: {required},
          identitas: {required},
          tanggal_lahir: {required},
          no_hp: {required}
        }
      },
      pengurus: {
        pendiri: {
          $each: {
            nama_pengurus: {required},
            identitas: {required}
          }
        },
        pembina: {
          $each: {
            nama_pengurus:  {required},
            identitas:  {required}
          }
        },
        penasehat: {
          $each: {
            nama_pengurus:  {required},
            identitas:  {required}
          }
        },
      }
    },
    formRekening: {
      $each: {
        no_rekening: {required},
        nama_bank: {required},
        // cabang_bank: {required},
        nama_pemilik_rekening: {required},
        id_jenis_rekening: {required}
      }
    },
    formCabang: {
        nama_cabang: {required},
        no_telp: {required},
        singkatan: {required},
        no_fax: {required},
        alamat_sekretariat: {required},
        npwp: {required},
        kabupaten_kota_sekretariat: {required},
        m_lokasi_sekretariat_id: {required},

        namaNotaris: {required},
        nomorAkta: {required},
        tanggalAkta: {required},
        kabupatenKota: {required},
        tanggalPendirian: {required},
        nomorSuratPendaftaran: {required},
        strukturOrganisasi: {
          $each: {
            nama_pengurus: {required},
            periode_mulai: {required},
            periode_selesai: {required},
            m_jabatan_id: {required},
            identitas: {required},
            tanggal_lahir: {required},
            no_hp: {required}
          }
        },
      },
      formRekeningC: {
        $each: {
          no_rekening: {required},
          nama_bank: {required},
          nama_pemilik_rekening: {required},
          id_jenis_rekening: {required}
        }
      },
      selectedCabangPropinsi: {required},
      selectedCabangPropinsi2: {required},
      
  },
  mounted() {
    this.$store.dispatch('getProfile').then(() => {
      this.getDataOrmasTemp()
      this.getCiriKhusus()
      this.getStatusSekretariat()
      this.getPropinsi()
      this.getBidangKegiatan()
      this.getBadanOtonom()
      this.getJabatan()
      this.getJenisRekening()
    })

    var d = new Date();
    var n = d.getFullYear();
    var nn = n - 2000 + 1

    for(var i = 0; i < nn; i++){
      if (i < 10) this.yearMB.push('200' + i)
      else this.yearMB.push('20' + i)
    }
  },
  watch:{
    selectedPropinsi(to){
      this.getKota(to)
    },
    selectedPendiriPropinsi(to){
      this.getKota2(to)
    },
    selectedCabangPropinsi(to){
      this.getKota3(to)
    },
    selectedCabangPropinsi2(to){
      this.getKota4(to)
    },
    activeTab(to){
      if (to == 4) {
        this.getListCabang()
      }
    },
    // listBadanOtonom(to){
    //   this.listt = to
    // }
    // $v(to) {
    // }
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    getListCabang(){
      this.$store
        .dispatch("getListCabang", null)
        .then((res) => {
          this.$nextTick(() => {
            this.listCabang = res.data.data
          })
        })
    },
    
    fileClicked(item){
      this.jenis_file = item
      setTimeout(() => {
        this.$refs.fileInput.click()  
      }, 500);
    },
    uploadFile(e){
      this.loadUpload = true
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.loadUpload = false
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadDocumentUmum', formData).then((res) =>{

        if(res.data.status != false){
          // "path": res.data.data.newFilePath,
          Swal.fire({
            title: "Upload File Berhasil",
            text: '',
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {
            if(this.jenis_file == 'logo') this.file_logo = res.data.data.newFilePath
            if(this.jenis_file == 'bendera') this.file_bendera = res.data.data.newFilePath
            if(this.jenis_file == 'skt') this.file_skt = res.data.data.newFilePath
            if(this.jenis_file == 'npwp') this.file_npwp = res.data.data.newFilePath
            if(this.jenis_file == 'scanNotaris') this.file_akta = res.data.data.newFilePath
            if(this.jenis_file == 'suratPermohonan') this.file_surat_pendaftaran = res.data.data.newFilePath
            if(this.jenis_file == 'skPengurus') this.file_sk_pengurus = res.data.data.newFilePath
            // {               
            //   this.cekProfile('f')
            // }
            if(this.jenis_file == 'skPengurusCab') this.file_sk_pengurus_cabang = res.data.data.newFilePath
            for(var i = 0; i < 3; i++){
              if(this.jenis_file == 'peng_foto' + i) this.formStruktur.pengurusInti[i].foto = res.data.data.newFilePath
              if(this.jenis_file == 'peng_ktp' + i) this.formStruktur.pengurusInti[i].file_identitas = res.data.data.newFilePath
              // this.formStruktur.pengurusInti[i].file_identitas_url
            }
            for(var a = 0; a < 3; a++){
              if(this.jenis_file == 'peng_foto_cab' + a) this.formCabang.strukturOrganisasi[a].foto = res.data.data.newFilePath
            }
            this.$forceUpdate()

          })
        }
        this.$refs.fileInput.value=null;
      }).catch((err) =>{
        this.loadUpload = false
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.$refs.fileInput.value=null;
      })
    },
    getDataOrmasTemp(){
      var us = JSON.parse(localStorage.getItem('user')).username
      this.$store
      .dispatch("getDataOrmas")
      .then((res) => {
        if (res.data.status == false) {
          this.getDataOrmas();
          return;
        }

        this.$nextTick(() => {
          var tmpSyarat= res.data.data.tempSyarat
          var base = res.data.data.tempDataOrmas
          this.statDis = base
          this.statDisTemp = tmpSyarat
          if (res && base && tmpSyarat) {
            if(base.nama_ketua != null && base.nama_ketua != '' || this.getUrlKTPketua !=''){
              this.formStruktur.pengurusInti[0].nameDis = true
              this.formStruktur.pengurusInti[0].m_jabatan_id = 1
              this.formStruktur.pengurusInti[0].file_identitas = this.getUrlKTPketua
              this.formStruktur.pengurusInti[0].nama_pengurus = base.nama_ketua
            }
            if(base.nama_sekretaris != null && base.nama_sekretaris != '' || this.getUrlKTPsekretaris !=''){
              this.formStruktur.pengurusInti[1].nameDis = true
              this.formStruktur.pengurusInti[1].m_jabatan_id = 3
              this.formStruktur.pengurusInti[1].file_identitas = this.getUrlKTPsekretaris
              this.formStruktur.pengurusInti[1].nama_pengurus = base.nama_sekretaris
            }
            if(base.nama_bendahara != null && base.nama_bendahara != '' || this.getUrlKTPbendahara != ''){
              this.formStruktur.pengurusInti[2].nameDis = true
              this.formStruktur.pengurusInti[2].m_jabatan_id = 4
              this.formStruktur.pengurusInti[2].file_identitas = this.getUrlKTPbendahara
              this.formStruktur.pengurusInti[2].nama_pengurus = base.nama_bendahara
            }
            if(base.nik_ketua != null && base.nik_ketua != ''){
              this.formStruktur.pengurusInti[0].idDis = true
              this.formStruktur.pengurusInti[0].identitas = base.nik_ketua
            }
            if(base.nik_sekretaris != null && base.nik_sekretaris != ''){
              this.formStruktur.pengurusInti[1].idDis = true
              this.formStruktur.pengurusInti[1].identitas = base.nik_sekretaris
            }
            if(base.nik_bendahara != null && base.nik_bendahara != ''){
              this.formStruktur.pengurusInti[2].idDis = true
              this.formStruktur.pengurusInti[2].identitas = base.nik_bendahara
            }

            if(base.tgl_masa_bakti_awal_ketua != null && base.tgl_masa_bakti_awal_ketua != ''){
              this.formStruktur.pengurusInti[0].pmDis = true
              this.formStruktur.pengurusInti[0].periode_mulai = base.tgl_masa_bakti_awal_ketua
            }
            if(base.tgl_masa_bakti_awal_sekretaris != null && base.tgl_masa_bakti_awal_sekretaris != ''){
              this.formStruktur.pengurusInti[1].pmDis = true
              this.formStruktur.pengurusInti[1].periode_mulai = base.tgl_masa_bakti_awal_sekretaris
            }
            if(base.tgl_masa_bakti_awal_bendahara != null && base.tgl_masa_bakti_awal_bendahara != ''){
              this.formStruktur.pengurusInti[2].pmDis = true
              this.formStruktur.pengurusInti[2].periode_mulai = base.tgl_masa_bakti_awal_bendahara
            }

            if(base.tgl_masa_bakti_akhir_ketua != null && base.tgl_masa_bakti_akhir_ketua != ''){
              this.formStruktur.pengurusInti[0].psDis = true
              this.formStruktur.pengurusInti[0].periode_selesai = base.tgl_masa_bakti_akhir_ketua
            }
            if(base.tgl_masa_bakti_akhir_sekretaris != null && base.tgl_masa_bakti_akhir_sekretaris != null){
              this.formStruktur.pengurusInti[1].psDis = true
              this.formStruktur.pengurusInti[1].periode_selesai = base.tgl_masa_bakti_akhir_ketua
            }
            if(base.tgl_masa_bakti_akhir_bendahara != null && base.tgl_masa_bakti_akhir_bendahara != null){
              this.formStruktur.pengurusInti[2].psDis = true
              this.formStruktur.pengurusInti[2].periode_selesai = base.tgl_masa_bakti_akhir_bendahara
            }

            if(base.nama_pendiri != null && base.nama_pendiri != '') {
              this.formStruktur.pengurus.pendiri[0].nama_pengurus = base.nama_pendiri
              this.formStruktur.pengurus.pendiri[0].dis = true
            }
            if(base.nama_pembina != null && base.nama_pembina != ''){ 
              this.formStruktur.pengurus.pembina[0].nama_pengurus = base.nama_pembina
              this.formStruktur.pengurus.pembina[0].dis = true
            }
            if(base.nama_penasihat != null && base.nama_penasihat != '') {
              this.formStruktur.pengurus.penasehat[0].nama_pengurus = base.nama_penasihat
              this.formStruktur.pengurus.penasehat[0].dis = true
            }

            if(base.nik_pendiri != null && base.nik_pendiri != '') {
              this.formStruktur.pengurus.pendiri[0].identitas = base.nik_pendiri
              this.formStruktur.pengurus.pendiri[0].disid = true
            }
            if(base.nik_pembina != null && base.nik_pembina != '') {
              this.formStruktur.pengurus.pembina[0].identitas = base.nik_pembina
              this.formStruktur.pengurus.pembina[0].disid = true
            }
            if(base.nik_penasihat != null && base.nik_penasihat != '') {
              this.formStruktur.pengurus.penasehat[0].identitas = base.nik_penasihat
              this.formStruktur.pengurus.penasehat[0].disid = true
            }
          }

          this.formProfil.nama_ormas = base.nama_ormas
          this.formProfil.singkatan = base.nama_singkatan
          this.formProfil.no_skt = us
          this.formProfil.alamat_sekretariat = base.alamat_sekretariat
          this.formProfil.no_telp = base.no_telp
          this.formProfil.no_fax = base.no_fax
          this.formProfil.email = base.email_ormas
          this.formProfil.status_sekretariat = 1
          this.formProfil.kabupaten_kota_sekretariat = base.kode_kabkota_alamat
          this.formProfil.tujuan_ormas = base.tujuan_ormas
          this.formProfil.npwp = base.no_npwp
          // this.formProfil.file_esign_skt = base.file_esign_skt
          // this.formProfil.file_scan_notaris = base.file_scan_notaris
          // this.formProfil.file_scan_npwp = base.file_scan_npwp
          this.file_surat_pendaftaran = base.file_scan_surat_permohonan

          this.file_skt = base.file_esign_skt
          this.file_npwp = base.file_scan_npwp
          this.formPendiri.nomorAkta = base.no_akta_notaris
          this.formPendiri.tanggalPendirian = base.tanggal_pendirian
          this.formPendiri.nomorSuratPendaftaran = base.no_surat_permohonan
          this.formPendiri.tanggalSuratPendaftaran = base.tgl_surat_permohonan
          this.file_akta = base.file_scan_notaris

          this.file_sk_pengurus = this.getUrlSKPersyaratan

          this.selectedPropinsi = base.kode_prov_alamat
        })
      })
    },
    getDataOrmas() {
      const us = JSON.parse(localStorage.getItem('user')).username

      this.$store
        .dispatch("getProfileOrganization", us)
        .then((res) => {
          const data = res.data

          if (!data) return false

          this.file_logo = data.file_logo
          this.file_bendera = data.file_bendera
          this.formProfil.nama_ormas = data.nama_ormas
          this.formProfil.singkatan = data.nama_singkat
          this.formProfil.no_skt = data.no_skt
          this.formProfil.alamat_sekretariat = data.alamat_sekretariat
          this.formProfil.no_telp = data.no_telp
          this.formProfil.no_fax = data.no_fax
          this.formProfil.email = data.email
          this.formProfil.status_sekretariat = 1
          this.formProfil.provinsi_sekretariat = data.provinsi_sekretariat
          this.formProfil.kabupaten_kota_sekretariat = data.kabupaten_kota_sekretariat
          this.formProfil.tujuan_ormas = data.tujuanOrmas ? data.tujuanOrmas[0].keterangan : null
          this.formProfil.npwp = data.npwp
          this.formProfil.m_ciri_khusus_id = data.m_ciri_khusus_id

          if (data.bidangKegiatans != undefined) {
            const bidangKegiatans = data.bidangKegiatans

            bidangKegiatans.forEach((value, key) => {
              this.formProfil.bidang_kegiatan[key].id = value.m_jenis_ormas_id
            })
          }

          if (
            data.berhimpunOtonom != undefined
            && data.berhimpunOtonom.length > 0
          ) {
            const berhimpunOtonom = data.berhimpunOtonom

            let key = 0;
            berhimpunOtonom.forEach((value) => {
              if (value.jenis == 1) {
                this.formProfil.wadahBerhimpun = value.afiliasiOrmasId
              } else {
                this.formProfil.badan_otonom[key] = {id: value.afiliasiOrmasId}

                key++
              }
            })
          }

          this.file_skt = data.file_skt
          this.file_npwp = data.file_npwp

          this.selectedPropinsi = data.provinsi_sekretariat

          // Profil Ormas
          this.$store.dispatch("getFounderOrganization", data.id)
            .then((res) => {
              const pendirian = res.data

              if (pendirian == null) return false

              this.formPendiri.namaNotaris = pendirian.namaNotaris
              this.formPendiri.nomorAkta = pendirian.nomorAkta
              this.formPendiri.tanggalAkta = pendirian.tanggalAkta
              this.formPendiri.tanggalPendirian = pendirian.tanggalPendirian
              this.formPendiri.nomorSuratPendaftaran = pendirian.nomorSuratPendaftaran
              this.formPendiri.tanggalSuratPendaftaran = pendirian.tanggalSuratPendaftaran
              this.formPendiri.file_akta = pendirian.file_akta
              this.formPendiri.provinsi = pendirian.provinsi ? pendirian.provinsi : data.provinsi_sekretariat
              this.formPendiri.kabupatenKota = pendirian.kabupatenKota ? pendirian.kabupatenKota : data.kabupaten_kota_sekretariat
              this.file_akta = pendirian.file_akta
              this.file_surat_pendaftaran = pendirian.file_surat_pendaftaran

              this.selectedPendiriPropinsi = data.provinsi_sekretariat
              this.file_sk_pengurus = data.file_sk_pengurus
            })

          // Struktur Organisasi
          this.$store.dispatch("getStructureOrganization", data.id)
            .then((res) => {
              const pengurus = res.data

              if (pengurus.pengurusInti.length > 0) {
                const pengurusInti = pengurus.pengurusInti
                
                pengurusInti.forEach((value, key) => {
                  // this.formStruktur.pengurusInti[key].nameDis = true
                  this.formStruktur.pengurusInti[key].nama_pengurus = value.nama_pengurus
                  this.formStruktur.pengurusInti[key].periode_mulai = value.periode_mulai
                  this.formStruktur.pengurusInti[key].periode_selesai = value.periode_selesai
                  this.formStruktur.pengurusInti[key].m_jabatan_id = value.m_jabatan_id
                  this.formStruktur.pengurusInti[key].identitas = value.identitas
                  this.formStruktur.pengurusInti[key].tempat_lahir = '-'
                  this.formStruktur.pengurusInti[key].tanggal_lahir = value.tanggal_lahir
                  this.formStruktur.pengurusInti[key].no_hp = value.no_hp
                  this.formStruktur.pengurusInti[key].foto = value.foto
                  this.formStruktur.pengurusInti[key].file_identitas = value.file_identitas
                })
              }

              if (pengurus.listPengurus.length > 0) {
                const listPengurus = pengurus.listPengurus
                this.formStruktur.pengurus.pendiri = []
                this.formStruktur.pengurus.pembina = []
                this.formStruktur.pengurus.penasehat = []
                this.formStruktur.pengurus.pelindung = []

                listPengurus.forEach((value) => {
                  // this.formStruktur.pengurus[value.masterKategoriPengurus.kode_kategori_pengurus].push[key].nama_pengurus = value.nama_pengurus
                  // this.formStruktur.pengurus[value.masterKategoriPengurus.kode_kategori_pengurus].push[key].identitas = value.identitas
                  this.formStruktur.pengurus[value.masterKategoriPengurus.kode_kategori_pengurus].push({
                    nama_pengurus: value.nama_pengurus,
                    identitas: value.identitas
                  })
                })
              }
            })

          // Rekening Ormas
          this.$store.dispatch("getBankAccountOrganization", data.id)
            .then((res) => {
              const rekening = res.data

              if (rekening.length > 0) {
                this.formRekening = []

                rekening.forEach((value) => {
                  this.formRekening.push({
                    no_rekening: value.no_rekening,
                    nama_bank: value.nama_bank,
                    cabang_bank: value.cabang_bank,
                    nama_pemilik_rekening: value.nama_pemilik_rekening,
                    id_jenis_rekening: value.id_jenis_rekening
                  })
                })
              }
            })
        })
    },
    validateStateN() {
      const { $dirty, $error } = this.$v.selectedPropinsi;
      return $dirty ? !$error : null;
    },
    validateStateP() {
      const { $dirty, $error } = this.$v.selectedPendiriPropinsi;
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.formPendiri[name];
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formProfil[name];
      return $dirty ? !$error : null;
    },
    validateStateFPBid(index){
      const { $dirty, $error } = this.$v.formProfil.bidang_kegiatan.$each.$iter[index];
      return $dirty ? !$error : null;
    },
    validateStateFPBid2(index){
      const { $dirty, $error } = this.$v.formProfil.badan_otonom.$each.$iter[index];
      return $dirty ? !$error : null;
    },
    validateStateSOid(index, name){
      const { $dirty, $error } = this.$v.formStruktur.pengurusInti.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateSOP(index, name){
      const { $dirty, $error } = this.$v.formStruktur.pengurus.pendiri.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateSOPM(index, name){
      const { $dirty, $error } = this.$v.formStruktur.pengurus.pembina.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateSOPN(index, name){
      const { $dirty, $error } = this.$v.formStruktur.pengurus.penasehat.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateRek(index, name){
      const { $dirty, $error } = this.$v.formRekening.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateC(name) {
      const { $dirty, $error } = this.$v.formCabang[name];
      return $dirty ? !$error : null;
    },
    validateStateCS(index, name){
      const { $dirty, $error } = this.$v.formCabang.strukturOrganisasi.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateRekC(index, name){
      const { $dirty, $error } = this.$v.formRekeningC.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateCP2(){
      const { $dirty, $error } = this.$v.selectedCabangPropinsi2;
      return $dirty ? !$error : null;
    },
    validateStateCP(){
      const { $dirty, $error } = this.$v.selectedCabangPropinsi;
      return $dirty ? !$error : null;
    },
    addRek(){
      this.$v.formRekening.$touch();
      if (!this.$v.formRekening.$anyError) {
        // this.loading = false
        // return;\
        this.formRekening.push({no_rekening: '',nama_bank: '',cabang_bank: '',nama_pemilik_rekening: '',id_jenis_rekening: null})
      }
      
    },
    addRekC(){
      this.$v.formRekeningC.$touch();
      if (!this.$v.formRekeningC.$anyError) {
        // this.loading = false
        // return;\
        this.formRekeningC.push({no_rekening: '',nama_bank: '',cabang_bank: '',nama_pemilik_rekening: '',id_jenis_rekening: null})
      }
      
    },
    setEnd(val, ind){
      if(this.yearMBend[ind].length > 0) this.yearMBend[ind].splice(-5)
      var nn = val - 2000
      for(var i = 0; i < 5; i++){
        var aa = nn + i
        if (aa < 10) this.yearMBend[ind].push('200' + aa)
        else this.yearMBend[ind].push('20' + aa)
      }
    },
    newTab(url){
      if(url.substring(0,4) == 'http') window.open(url);
      else window.open(this.fileURL + '/' + url);
    },
    cekProfile(m){
      this.$v.formProfil.$touch();
      this.$v.selectedPropinsi.$touch()
      if (this.$v.formProfil.$anyError || this.$v.selectedPropinsi.$anyError){
        return;
      }
      // if (this.$v.file_skt == ''){
      //   alert('Silahkan Unggah File SKT!');
      // }
      // if (this.$v.file_npwp == ''){
      //   alert('Silahkan Upload File NPWP!');
      // }
      
      var valbid = []
      for(var a = 0; a < this.formProfil.bidang_kegiatan.length; a++){
        valbid.push(this.formProfil.bidang_kegiatan[a].id)
      }
      var valbdon = []
      for(var b = 0; b < this.formProfil.badan_otonom.length; b++){
        valbdon.push(this.formProfil.badan_otonom[b].id)
      }
      var dataform = {
                    "dataOrmas": {
                      "nama_ormas": this.formProfil.nama_ormas,
                      "nama_singkat": this.formProfil.singkatan,
                      "npwp": this.formProfil.npwp,
                      // "m_kategori_ormas_id": 1,
                      "alamat_sekretariat": this.formProfil.alamat_sekretariat,
                      "no_skt": this.formProfil.no_skt,
                      // "tanggal_skt": "2021-09-30T02:26:10.459Z",
                      // "tanggal_berdiri": "2021-09-30T02:26:10.459Z",
                      "email": this.formProfil.email,
                      "no_telp": this.formProfil.no_telp,
                      "no_fax": this.formProfil.no_fax,
                      // "website": "string",
                      "file_logo": this.file_logo,
                      "file_bendera": this.file_bendera,
                      "file_skt": this.file_skt,
                      "file_npwp": this.file_npwp,
                      "file_sk_pengurus": this.file_sk_pengurus,
                      
                      "status_ormas": 0,
                      "status_badan_hukum": 0,
                      "status_legalitas": 0,
                      "kode_transaksi": this.statDis && this.statDis.kode_transaksi ? this.statDis.kode_transaksi : '',
                      "m_lokasi_sekretariat_id": this.formProfil.status_sekretariat,
                      "provinsi_sekretariat": this.selectedPropinsi,
                      "kabupaten_kota_sekretariat": this.formProfil.kabupaten_kota_sekretariat,
                      "m_ciri_khusus_id": this.formProfil.m_ciri_khusus_id
                    },
                    "bidangKegiatan": valbid,
                    "wadahBerhimpun": this.formProfil.wadahBerhimpun == '' ? null : this.formProfil.wadahBerhimpun,
                    "badanOtonom": valbdon[0] == '' ? [] : valbdon,
                    "tujuanOrmas": this.formProfil.tujuan_ormas
                  }
                  if(valbdon[0] == '') delete dataform.badanOtonom 
                  if(this.formProfil.wadahBerhimpun == '') delete dataform.wadahBerhimpun

      this.$store
        .dispatch("submitKelengkapanProfil", dataform)
        .then((res) => {
          if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: bb != '' ? "success" : "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            // Kalau trigger paksa simpan profil, tidak akan melanjutkan proses berikutnya
            if(m == 'f') return false

            Swal.fire({
              title: res.data.message,
              text: '',
              icon: "success",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
            var idnya = res.data.data.newData.id
            localStorage.setItem('idOrmas', idnya)
            if(!this.vProfile) {
              this.vProfile = true
              this.progress = this.progress + 25
            }
            this.activeTab = 1
            window.scrollTo(0,0);
          }
        }).catch(() =>{
          Swal.fire({
            title: 'Silahkan Upload File SKT/NPWP Organisasi',
            text: '',
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok",
            cancelButtonText: false
          })
        })
    },
    cekInfoPendirian(){
      this.$v.formPendiri.$touch();
      this.$v.selectedPendiriPropinsi.$touch();
      if (this.$v.formPendiri.$anyError || this.$v.selectedPendiriPropinsi.$anyError) {
        return;
      }
      this.formPendiri.file_akta = this.file_akta
      this.formPendiri.file_surat_pendaftaran = this.file_surat_pendaftaran
      this.formPendiri.provinsi = this.selectedPendiriPropinsi
      this.formPendiri.tanggalAkta = this.formPendiri.tanggalAkta.substring(0, 10)
      this.formPendiri.tanggalPendirian = this.formPendiri.tanggalPendirian.substring(0, 10)
      this.formPendiri.tanggalSuratPendaftaran = this.formPendiri.tanggalSuratPendaftaran.substring(0, 10)
      this.$store
        .dispatch("submitKelengkapanInfoPendiri", this.formPendiri)
        .then((res) => {
          if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            if(!this.vPendiri) {
              this.vPendiri = true
              this.progress = this.progress + 25
            }
            this.activeTab = 2
            window.scrollTo(0,0);
          }
        }).catch(() =>{
          Swal.fire({
            title: 'Terjadi Kesalahan',
            text: '',
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok",
            cancelButtonText: false
          })
        })
      
    },
    cekStruktur(){
      this.$v.formStruktur.$touch();
      if (this.$v.formStruktur.$anyError) {
        return;
      }

      for(var i = 0; i < this.formStruktur.pengurusInti.length; i++){
        delete this.formStruktur.pengurusInti[i].idDis;
        delete this.formStruktur.pengurusInti[i].nameDis;
        delete this.formStruktur.pengurusInti[i].pmDis;
        delete this.formStruktur.pengurusInti[i].psDis;
        
        this.formStruktur.pengurusInti[i].periode_mulai = this.formStruktur.pengurusInti[i].periode_mulai.substring(0, 10)
        this.formStruktur.pengurusInti[i].periode_selesai = this.formStruktur.pengurusInti[i].periode_selesai.substring(0, 10)
        this.formStruktur.pengurusInti[i].tanggal_lahir = this.formStruktur.pengurusInti[i].tanggal_lahir.substring(0, 10)
      }

      for(var a = 0; a < this.formStruktur.pengurus.pendiri.length; a++){
        delete this.formStruktur.pengurus.pendiri[a].dis;
        delete this.formStruktur.pengurus.pendiri[a].disid;
      }

      for(var b = 0; b < this.formStruktur.pengurus.penasehat.length; b++){
        delete this.formStruktur.pengurus.penasehat[b].dis;
        delete this.formStruktur.pengurus.penasehat[b].disid;
      }

      for(var c = 0; c < this.formStruktur.pengurus.pembina.length; c++){
        delete this.formStruktur.pengurus.pembina[c].dis;
        delete this.formStruktur.pengurus.pembina[c].disid;
      }

      this.$store
        .dispatch("submitKelengkapanStruktur", this.formStruktur)
        .then((res) => {
         if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            // Simpan ulang profil untuk update sk pengurus
            if (this.file_sk_pengurus) this.cekProfile('f')

            if(!this.vStruktur) {
              this.vStruktur = true
              this.progress = this.progress + 25
            }
            this.activeTab = 3
            window.scrollTo(0,0);
          }
        }).catch(() =>{
          Swal.fire({
            title: 'Terjadi Kesalahan',
            text: '',
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok",
            cancelButtonText: false
          })
        })

    },
    cekRekening(){
      this.$v.formRekening.$touch();
      if (this.$v.formRekening.$anyError) {
        return;
      }
      this.$store
      .dispatch("submitKelengkapanRekening", this.formRekening)
      .then((res) => {
        if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            if(!this.vRekening) {
              this.vRekening = true
              this.progress = this.progress + 25
            }
            this.activeTab = 4
            window.scrollTo(0,0);
          }
      }).catch(() =>{
          Swal.fire({
            title: 'Terjadi Kesalahan',
            text: '',
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok",
            cancelButtonText: false
          })
        })
      
    },
    deleteCabang(id){
      const context = this
      Swal.fire({
            title: "Anda yakin ingin menghapus cabang?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Hapus",
          }).then((result) => {
            if (result.isConfirmed) {
              context.$store.dispatch("deleteCabangKelengkapan", id).then((/*res*/) => {
                Swal.fire({
                  title: "Cabang berhasil dihapus",
                  text: "",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Lanjut",
                }).then(() => {
                  context.getListCabang()
                })
              })
            }
          });
    },
    saveCabang(){
      this.$v.formCabang.$touch();
      this.$v.formRekeningC.$touch();
      this.$v.selectedCabangPropinsi.$touch()
      this.$v.selectedCabangPropinsi2.$touch()
      if (this.$v.formCabang.$anyError || this.$v.selectedCabangPropinsi.$anyError || this.$v.selectedCabangPropinsi2.$anyError || this.$v.formRekeningC.$anyError) {
        // this.loading = false
        return;
      }
      
      var rek = []
      for(var a=0 ; a < this.formRekeningC.length; a++){
        rek.push({
          no_rekening: this.formRekeningC[a].no_rekening,
          nama_bank: this.formRekeningC[a].nama_bank,
          nama_pemilik_rekening: this.formRekeningC[a].nama_pemilik_rekening,
          id_jenis_rekening: parseInt(this.formRekeningC[a].id_jenis_rekening)
        })
      }
      var dataform = {
        "profil": {
          "nama_cabang": this.formCabang.nama_cabang,
          "no_telp": this.formCabang.no_telp,
          "no_fax": this.formCabang.no_fax,
          "alamat_sekretariat": this.formCabang.alamat_sekretariat,
          "npwp": this.formCabang.npwp,
          "tanggal_berdiri": this.formCabang.tanggalPendirian,
          "provinsi_sekretariat": this.selectedCabangPropinsi,
          "kabupaten_kota_sekretariat": this.formCabang.kabupaten_kota_sekretariat,
          "m_lokasi_sekretariat_id": this.formCabang.m_lokasi_sekretariat_id,
          "file_sk_pengurus_cabang": this.file_sk_pengurus_cabang
        },
        "infoPendirian": {
          "namaNotaris": this.formCabang.namaNotaris,
          "nomorAkta": this.formCabang.nomorAkta,
          "tanggalAkta": this.formCabang.tanggalAkta,
          "kabupatenKota": this.formCabang.kabupatenKota,
          "tanggalPendirian": this.formCabang.tanggalPendirian,
          "nomorSuratPendaftaran": this.formCabang.nomorSuratPendaftaran,
          "tanggalSuratPendaftaran": this.formCabang.tanggalAkta,
          "provinsi": this.selectedCabangPropinsi2
        },
        "strukturOrganisasi": this.formCabang.strukturOrganisasi,
        "rekening": rek
      }

      var submittingTo = ''

      if(this.createMode){
        submittingTo = 'submitKelengkapanCabang'
        this.$store
        .dispatch(submittingTo, dataform)
        .then((res) => {
         if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            this.getListCabang()
            this.$bvModal.hide('modal-form-cabang')
            window.scrollTo(0,0);
            this.resetFormCabang()
          }
        }).catch(() =>{
          Swal.fire({
            title: 'Terjadi Kesalahan',
            text: '',
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok",
            cancelButtonText: false
          })
        })
      } else{ 
        submittingTo = 'submitKelengkapanCabangPatch'
        this.$store
        .dispatch(submittingTo, [dataform, this.idCabang])
        .then((res) => {
          if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
          } else {
            this.getListCabang()
            this.$bvModal.hide('modal-form-cabang')
            window.scrollTo(0,0);
            this.resetFormCabang()
          }
        }).catch(() =>{
          Swal.fire({
            title: 'Terjadi Kesalahan',
            text: '',
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok",
            cancelButtonText: false
          })
        })
        
      }
      
    },
    resetFormCabang(){
      this.formCabang = {
        nama_cabang: '',
        no_telp: '',
        singkatan:'',
        no_fax: '',
        alamat_sekretariat: '',
        npwp: '',
        tanggal_berdiri: '',
        kabupaten_kota_sekretariat: '',
        m_lokasi_sekretariat_id: '',

        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: '',
        kabupatenKota: '',
        tanggalPendirian: '',
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: '-',
        strukturOrganisasi: [
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            tempat_lahir: '-',
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          }
        ],
      }
      this.formRekeningC = [
        {
          no_rekening: '',
          nama_bank: '',
          // cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening:''  
        }
      ]

      this.selectedCabangPropinsi = ''
      this.selectedCabangPropinsi2 = ''
      
      this.file_sk_pengurus_cabang = ''

    },
    fillFormCabang(d, id){
      var peng = []
      for(var b=0 ; b < d.pengurusOrmas.length; b++){
        var dd = d.pengurusOrmas[b]
        peng.push(
          {
            nama_pengurus: dd.nama_pengurus,
            periode_mulai: dd.periode_mulai.substring(0, 10),
            periode_selesai: dd.periode_selesai.substring(0, 10),
            m_jabatan_id: dd.m_jabatan_id,
            identitas: dd.identitas,
            tempat_lahir: dd.tempat_lahir,
            tanggal_lahir: dd.tanggal_lahir.substring(0, 10),
            no_hp: dd.no_hp,
            foto: dd.foto
          }
        )
      }

      this.idCabang = id
      this.formCabang = {
        nama_cabang: d.nama_cabang,
        no_telp: d.no_telp,
        singkatan:'-',
        no_fax: d.no_fax,
        alamat_sekretariat: d.alamat_sekretariat,
        npwp: d.npwp,
        tanggal_berdiri: d.tanggal_berdiri.substring(0, 10),
        kabupaten_kota_sekretariat: d.kabupaten_kota_sekretariat,
        m_lokasi_sekretariat_id: d.m_lokasi_sekretariat_id,

        namaNotaris: d.infoPendirian.namaNotaris,
        nomorAkta: d.infoPendirian.nomorAkta,
        tanggalAkta: d.infoPendirian.tanggalAkta.substring(0, 10),
        kabupatenKota: d.infoPendirian.kabupatenKota,
        tanggalPendirian: d.infoPendirian.tanggalPendirian.substring(0, 10),
        nomorSuratPendaftaran: d.infoPendirian.nomorSuratPendaftaran,
        tanggalSuratPendaftaran: d.infoPendirian.tanggalSuratPendaftaran.substring(0, 10),
        strukturOrganisasi: peng,
      }
      
      this.selectedCabangPropinsi = d.provinsi_sekretariat
      this.selectedCabangPropinsi2 = d.infoPendirian.provinsi

      var rek = []
      for(var a=0 ; a < d.rekeningOrmas.length; a++){
        rek.push({
          no_rekening: d.rekeningOrmas[a].no_rekening,
          nama_bank: d.rekeningOrmas[a].nama_bank,
          nama_pemilik_rekening: d.rekeningOrmas[a].nama_pemilik_rekening,
          id_jenis_rekening: d.rekeningOrmas[a].id_jenis_rekening
        })
      }
      this.formRekeningC = rek

      
    },
    addCabang(){
      this.resetFormCabang()
      this.$v.$reset()
      this.createMode = true
      this.formCabang.nama_cabang = this.formProfil.nama_ormas
      this.$bvModal.show('modal-form-cabang')
    },
    editCabang(id){
      this.$v.$reset()
      this.$store
        .dispatch('getDetailCabang',id)
        .then((res) => {
          this.resetFormCabang()
          this.createMode = false
          this.$bvModal.show('modal-form-cabang')    
          this.resetFormCabang()
          this.fillFormCabang(res.data.data, id)
        })
    },
    submitKelengkapan(){
      if(this.progress != 100){
        Swal.fire({
          title: "Data Belum Lengkap",
          text: '',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#d33",
          confirmButtonText: "Tutup",
        })
        return false
      }
      this.loadingSubmit = true
      this.$store
        .dispatch("submitKelengkapan")
        .then((res) => {
          if(!res.data.status){
            let propertyValues = Object.values(res.data.error);
            var bb = ''
            for(var i = 0; i < propertyValues.length; i++){
              bb += ' ' + propertyValues[i] + ','
            }
            bb.slice(0, -1)
            Swal.fire({
              title: res.data.message,
              text: bb,
              icon: "warning",
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok",
              cancelButtonText: false
            })
            this.loadingSubmit = false
          } else {
            this.$router.push('/dashboard')
            this.loadingSubmit = false
            Swal.fire({
              title: "Data Berhasil Diajukan",
              text: "",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Lanjut",
            })
          }  
        })
        .catch((err)=> {
          this.loadingSubmit = false
          Swal.fire({
            title: "Data Gagal Diajukan",
            text: err.message,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#d33",
            confirmButtonText: "Tutup",
          }).then((result) => {
            if (result.isConfirmed) {
              window.scrollTo(0,0);
            }
          });
        }) 
    },
    cekCabang(){
      if(!this.vCabang) {
        this.vCabang = true
        // this.progress = this.progress + 25
      }
      this.$router.push('/dashboard')
    },
    back(){
      this.activeTab = this.activeTab - 1
      window.scrollTo(0,0);
    },
    getStatusSekretariat(){
      this.$store
        .dispatch("getStatusSekretariat")
        .then((res) => {
          
          this.$nextTick(() => {
            this.listSekretariat = res.data
          })
          })
    },
    getPropinsi(){
      this.$store
        .dispatch("getPropinsi")
        .then((res) => {
          
           this.$nextTick(() => {
            this.listPropinsi = res.data.data
          })
          })
    },
    getCiriKhusus(){
      this.$store
        .dispatch("getCiriKhusus")
        .then((res) => {
           this.$nextTick(() => {
            this.listCiriKhusus = res.data
          })
          })
    },

    getBadanOtonom(){
      this.$store
        .dispatch("getBadanOtonom")
        .then((res) => {
          setTimeout(() => {
            this.$nextTick(() => {
              this.listBadanOtonom = res.data
              this.$forceUpdate()
            })
          }, 2000);
           
        })
    },

    getJabatan(){
      this.$store
      .dispatch("getJabatan")
      .then((res) => {
          this.$nextTick(() => {
          this.listJabatan = res.data
          for(var i = 0; i < res.data.length; i++){
            this.formStruktur.pengurusInti[i].m_jabatan_id = res.data[i].id
          }
        })
        })
    },
    getBidangKegiatan(){
      this.$store
        .dispatch("getBidangKegiatan")
        .then((res) => {
           this.$nextTick(() => {
            this.listBidangKegiatan = res.data
          })
          })
    },
    getJenisRekening(){
      this.$store
        .dispatch("getJenisRekening")
        .then((res) => {
           this.$nextTick(() => {
            this.listRekening = res.data
          })
          })
    },
    getKota(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota = res.data.data
          })
          })
    },
    getKota2(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota2 = res.data.data
          })
          })
    },
    getKota3(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota3 = res.data.data
          })
          })
    },
    getKota4(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota4 = res.data.data
          })
          })
    },
  },
  computed: {
    getUrlSKPersyaratan(){
      let file_url_sk_pengurus = null;
      if(this.statDisTemp){
        this.statDisTemp.forEach((value) => {

          if(value.id_persyaratan == '288') {
            file_url_sk_pengurus = value.url_file;
          }
        });
        
      }
      return file_url_sk_pengurus;
    },
    getUrlKTPketua(){
      let file_url_ktp_ketua = null;
      if(this.statDisTemp){
        this.statDisTemp.forEach((value) => {
          if(value.id_persyaratan == 291) {
            file_url_ktp_ketua = value.url_file;
          }
        });
        
      }
      return file_url_ktp_ketua;
    },
  getUrlKTPsekretaris(){
    
      let file_url_ktp_sekretaris = null;
      if(this.statDisTemp){
        this.statDisTemp.forEach((value) => {
          if(value.id_persyaratan == 294) {
            file_url_ktp_sekretaris = value.url_file;
          }
        });
        
      }
      return file_url_ktp_sekretaris;
    },
    getUrlKTPbendahara(){
      let file_url_ktp_bendahara = null;
      if(this.statDisTemp){
        this.statDisTemp.forEach((value) => {
          if(value.id_persyaratan == 297) {
            file_url_ktp_bendahara = value.url_file;
          }
        });
        
      }
      return file_url_ktp_bendahara;
    }   
  }
};
</script>

<style>
.nav.nav-pills .nav-link {
  color: #053A69;
  font-weight: bold;
}
.button-plus {
  background:#339933;cursor:pointer; padding:5px; border-radius:50%;width:30px; height:30px;text-align:center; margin-left:5px;

}
.button-minus {
  background:#FF4433;cursor:pointer; padding:5px; border-radius:50%;width:30px; height:30px;text-align:center; margin-left:5px;
}
.nav.nav-pills .nav-item {
    margin-right: 10px;
    margin-left: 10px;
}

.form-control.is-invalid{
  padding-right: 0px !important;
}
.form-control.is-valid{
  padding-right: 0px !important;
}
</style>

